<template>
    <div class="px-2 sm:px-10 py-4">
        <Header
            :title="content.title"
            :description="content.description"
            :profile="{
                avatar: get(state.currentUser, 'avatar'),
                name:
                    get(state.currentUser, 'firstName', '') +
                    ' ' +
                    get(state.currentUser, 'lastName', ''),
            }"
        />
        <div class="mt-10">
            <div>
                <ListViewHeader
                    v-bind="{
                        ...content.ideaHeader,
                        title: `Ideas and Ventures (${ventures?.length})`,
                    }"
                    @onClick="() => (isShowIdea = true)"
                />
                <div v-if="ventures?.length > 0" class="space-y-3 mt-5">
                    <CardWithBadge
                        v-for="idea in ventures"
                        :key="idea.id"
                        v-bind="idea"
                        @onClick="
                            $router.push({
                                name: 'Venture Dashboard',
                                params: { ventureId: idea.id },
                            })
                        "
                    />
                    <Text
                        @click="$router.push('/ventures')"
                        size="sm"
                        color="primary-600"
                        content="View all"
                        decoration="underline"
                        custom-class="cursor-pointer"
                    />
                </div>
                <EmptyState
                    v-else
                    class="bg-white mt-5"
                    isFull
                    v-bind="content.empty"
                    :action="() => (isShowIdea = true)"
                />
            </div>
        </div>
        <!-- <div class="mt-10">
      <ListViewHeader title="Resources" />
      <div class="space-y-3 mt-5">
        <CardWithImage v-for="idea in resources" :key="idea.id" v-bind="idea" @onClick="() => $router.push({
          name: 'Resource Detail',
          params: { id: idea?.id },
        })
          " />
        <Text size="sm" color="primary-600" content="More Resources" decoration="underline" custom-class="cursor-pointer"
          @click="$router.push('/resources')" />
      </div>
    </div> -->
        <div v-if="companies?.length > 0" class="mt-10">
            <ListViewHeader title="Your Ecosystem" />
            <div class="space-y-3 mt-5">
                <CardWithBadge
                    v-for="idea in companies"
                    :key="idea.id"
                    v-bind="idea"
                />
                <!--					<Text size="sm" color="primary-600" content="More Resources" decoration="underline" custom-class="cursor-pointer"/>-->
            </div>
        </div>
        <WelcomeModal
            :open="isShow"
            :content="c.addIdea"
            v-bind="welcome"
            @onClose="() => onCloseWelcome(false)"
            @onCreateVenture="() => onCloseWelcome(true)"
        />
        <WithSwiper
            v-bind="{
                ...c.addIdea,
                isShow: isShowIdea,
                isLoading: false,
                isSaving: isSaving,
                formList: form,
                modelKey: 'modelField',
                finalData: formData,
            }"
            @saveForm="(formObject, index) => saveForm(formObject, index)"
            @onClose="
                () => {
                    isShowIdea = false;
                }
            "
        />
        <EcosystemAcceptModal
            :open="isShowEco"
            @onClose="() => onCloseEco()"
            @onCreateVenture="() => onCreateVenture()"
            :title="`Welcome to ${state.currentUser?.companies?.[0]?.name} - we're glad you are here!`"
            :textHtml="`Which of your idea would you like to use as part of ${state.currentUser?.companies?.[0]?.name}?`"
            :ventures="json(state.venture.ventures)"
            :text="`Sharing an idea with ${state.currentUser?.companies?.[0]?.name} will allow the admins to be able to see and edit your data so they can help you navigate each step of the process.`"
        />
        <WelcomeCohortModal
            :open="isShowCohort"
            @onClose="() => onCloseCohort()"
            @onCreateVenture="() => onCreateVenture()"
            :title="`Welcome to ${state.currentUser?.cohorts?.[0]?.program?.name} - we're glad you are here!`"
            :textHtml="`Which of your idea would you like to use as part of ${state.currentUser?.cohorts?.[0]?.program?.name}?`"
            :ventures="json(state.venture.ventures)"
            :text="`Sharing an idea with ${state.currentUser?.cohorts?.[0]?.program?.name} will allow the admins to be able to see and edit your data so they can help you navigate each step of the process.`"
        />
        <WelcomeEcosystemModal
            :open="isShowEcoWelcome"
            v-bind="welcomeEcosystem"
            @onClose="(isOpen) => onCloseEcoWelcome(isOpen)"
        />
        <SimpleAlert
            :isShow="isShowVenture"
            :title="`You've been added to the venture team <b>${
                state.venture.ventures?.[
                    state.currentUser?.metadata?.recentlyInvitedVentureId
                ]?.name
            }</b>`"
            icon="LightBulbIcon"
            yesBtnText="Go to Venture Team"
            :onYesClick="() => onCloseVentureAlert(true)"
            noBtnText="Cancel"
            :onNoClick="() => onCloseVentureAlert(false)"
            yesBtnVariant="primary"
            noBtnVariant="secondary"
            iconColor="primary"
        />
    </div>
</template>

<script>
import Header from "../components/molecules/PageViewHeader/WithAvatar/WithAvatar";
import EmptyState from "../components/organisms/EmptyState/Simple/Simple";
import ListViewHeader from "../components/molecules/ListViewHeader/WithTitleAndButton/WithTitleAndButton";
import CardWithBadge from "../components/molecules/CardListItem/CardWithBadge/CardWithBadge";
import CardWithImage from "../components/molecules/CardListItem/CardWithImage/CardWithImage";
import Text from "../components/atoms/Text/Text";
import { c } from "../components/constants.js";
import WelcomeModal from "../components/organisms/Modals/Large/Large";
import _, { get, lowerFirst } from "lodash";
import { form } from "../../.storybook/sampleData";
import WithSwiper from "@/components/organisms/Modals/WithSwiper/WithSwiper";
import EcosystemAcceptModal from "../components/organisms/Modals/EcosystemAcceptModal/EcosystemAcceptModal.vue";
import WelcomeCohortModal from "../components/organisms/Modals/WelcomeCohortModal/WelcomeCohortModal.vue";
import WelcomeEcosystemModal from "../components/organisms/Modals/WelcomeEcosystemModal/WelcomeEcosystemModal";
import SimpleAlert from "../components/organisms/Modals/SimpleAlert/SimpleAlert.vue";
import { json } from "overmind";
import { Settings } from "../../settings";
import { useSubscription } from "@vue/apollo-composable";
import { userSubscription } from "../store/effects/gql/user/subscriptions.js";

export default {
    components: {
        Header,
        EmptyState,
        ListViewHeader,
        CardWithImage,
        CardWithBadge,
        Text,
        WelcomeModal,
        WithSwiper,
        EcosystemAcceptModal,
        WelcomeCohortModal,
        WelcomeEcosystemModal,
        SimpleAlert,
    },
    data() {
        return {
            content: c.home,
            isShow: false,
            isShowIdea: false,
            welcome: c.welcome,
            get,
            c,
            ventures: [],
            resources: [],
            companies: [],
            form,
            venture: null,
            formData: {},
            isSaving: false,
            isShowEco: false,
            isShowCohort: false,
            isShowEcoWelcome: false,
            isShowVenture: false,
            welcomeEcosystem: c.welcomeEcosystem,
            json,
            result: null,
        };
    },
    async mounted() {
        $(document).keydown(function (objEvent) {
            if (objEvent.keyCode == 9) {
                //tab pressed
                objEvent.preventDefault(); // stops its action
            }
        });
        console.log("-=-=-=-=-=-=-=-=-=-=-=-=-=-");
        let params = await localStorage.getItem("register_params");
        params = JSON.parse(params);
        console.log(params, "params");
        if (params) {
            if (params?.eco && !params?.ch) {
                this.isShowEco = true;
            } else if (params?.ch) {
                this.isShowCohort = true;
            } else if (params?.eco && params?.isa) {
                this.isShowEcoWelcome = true;
            }
            localStorage.removeItem("register_params");
        } else {
            console.log(this.state.currentUser, "current user");
            if (this.state.currentUser?.metadata?.showAdminEcosystemModal) {
                this.isShowEcoWelcome = true;
            } else if (this.state.currentUser?.metadata?.showEcosystemModal) {
                this.isShowEco = true;
            } else if (this.state.currentUser?.metadata?.showCohortModal) {
                this.isShowCohort = true;
            } else if (this.state.currentUser?.metadata?.firstLogin) {
                this.isShow = this.state.currentUser?.metadata?.firstLogin;
            } else if (this.state.currentUser?.metadata?.showVentureAlert) {
                this.isShowVenture = true;
            }
        }

        // console.log("-------------------");
        // const result = useSubscription(userSubscription, {
        //     userId: this.state.currentUser?.id,
        // });
        // result.onResult((result) => {
        //     console.log(result, "-------xxx");
        //     //   refetch();
        //     this.result = result;
        // });
    },
    watch: {
        // result: async function (val) {
        //     console.log(val, "val");
        //     if (val?.data?.userSubscription?.node?.id) {
        //         const user = val?.data?.userSubscription?.node;
        //         if (user?.metadata?.showAdminEcosystemModal) {
        //             this.isShowEcoWelcome = true;
        //         } else if (user?.metadata?.showEcosystemModal) {
        //             this.isShowEco = true;
        //         } else if (user?.metadata?.showCohortModal) {
        //             this.isShowCohort = true;
        //         } else if (user?.metadata?.firstLogin) {
        //             this.isShow = this.state.currentUser?.metadata?.firstLogin;
        //         } else if (user?.metadata?.showVentureAlert) {
        //             this.isShowVenture = true;
        //         }
        //     }
        // },
    },
    async created() {
        try {
            this.formData.location = [Settings.usObj];
            await this.getVenturesData();
            await this.getResourcesData();
            console.log(this.formData, "formData");
            await this.actions.tag.getTags({
                all: true,
                where: { status: "APPROVED", name_not: "Prefer not to say" },
            });
            const ages = [],
                genders = [],
                incomes = [],
                locations = [],
                ethnicities = [],
                interests = [],
                revenues = [],
                employee_counts = [];
            json(this.state.tag.tags)
                .filter((t) => t.category === "AGE")
                ?.map((t) => {
                    ages.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.category === "GENDER")
                ?.map((t) => {
                    genders.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.category === "INCOME")
                ?.map((t) => {
                    incomes.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.type === "LOCATION")
                ?.map((t) => {
                    locations.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.category === "ETHNICITY")
                ?.map((t) => {
                    ethnicities.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.category === "DESCRIPTORS")
                ?.map((t) => {
                    interests.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.category === "REVENUE")
                ?.map((t) => {
                    revenues.push({ ...t, label: t.name });
                });
            json(this.state.tag.tags)
                .filter((t) => t.category === "EMPLOYEE_COUNT")
                ?.map((t) => {
                    employee_counts.push({ ...t, label: t.name });
                });
            this.form[2].list[0].fields[0].options = JSON.stringify(
                revenues?.sort((a, b) => (a?.sortOrder > b?.sortOrder ? 1 : -1))
            );
            this.form[2].list[1].fields[0].options =
                JSON.stringify(employee_counts);

            this.form[3].list[1].fields[0].options = JSON.stringify(ages);
            this.form[3].list[2].fields[0].options = JSON.stringify(genders);
            this.form[3].list[3].fields[0].options =
                JSON.stringify(ethnicities);
            this.form[3].list[4].fields[0].options = JSON.stringify(incomes);
            this.form[3].list[6].fields[0].options = JSON.stringify(
                revenues?.sort((a, b) => (a?.sortOrder > b?.sortOrder ? 1 : -1))
            );
            this.form[3].list[7].fields[0].options =
                JSON.stringify(employee_counts);
            // this.form[3].list[5].fields[0].options = JSON.stringify(locations);
            this.form[3].list[0].fields[0].options = JSON.stringify(
                interests?.filter((i) => i?.status === "APPROVED")
            );
            await this.actions.actionStep.getActionSteps({ all: true });
            await this.actions.economy.getEconomies({ all: true });
            const economies = [];
            this.state.economy.economies.map((o) =>
                economies?.push({
                    ...o,
                    name: o?.problem,
                    label: o?.problem,
                })
            );
            console.log(economies, "economies");
            const actionSteps = [];
            this.state.actionStep.actionSteps
                ?.filter((a) => a?.status === "APPROVED")
                .map((o) =>
                    actionSteps?.push({
                        ...o,
                        label: o?.name,
                    })
                );
            this.form[4].list[1].fields[0].options =
                JSON.stringify(actionSteps);
            this.form[4].list[2].fields[0].options = JSON.stringify(
                economies?.filter((e) => e?.name)
            );
        } catch (e) {
            console.log(e);
        }
    },
    methods: {
        async onCloseCohort() {
            this.isShowCohort = false;
            await this.actions.user.saveUser({
                where: { id: this.state.currentUser?.id },
                data: {
                    metadata: {
                        ...this.state.currentUser?.metadata,
                        showCohortModal: false,
                    },
                },
            });
        },
        async onCloseEco() {
            this.isShowEco = false;
            await this.actions.user.saveUser({
                where: { id: this.state.currentUser?.id },
                data: {
                    metadata: {
                        ...this.state.currentUser?.metadata,
                        showEcosystemModal: false,
                    },
                },
            });
        },
        async onCloseVentureAlert(isYes) {
            if (isYes) {
                this.$router.push({
                    name: "Venture Dashboard",
                    params: {
                        ventureId:
                            this.state.currentUser?.metadata
                                ?.recentlyInvitedVentureId,
                    },
                });
            }
            await this.actions.user.saveUser({
                where: { id: this.state.currentUser?.id },
                data: {
                    metadata: {
                        ...this.state.currentUser?.metadata,
                        showVentureAlert: false,
                    },
                },
            });
        },
        async onCloseEcoWelcome(isOpen) {
            try {
                this.isShowEcoWelcome = false;
                this.$router.push({
                    name: "Ecosystem Detail",
                    params: {
                        companyId: this.state.currentUser?.companies?.[0]?.id,
                    },
                });
                await this.actions.user.saveUser({
                    where: { id: this.state.currentUser?.id },
                    data: {
                        metadata: {
                            ...this.state.currentUser?.metadata,
                            firstLogin: false,
                            showAdminEcosystemModal: false,
                            showEcosystemModal: false,
                        },
                    },
                });
            } catch (e) {
                console.log(e);
            }
        },
        onCreateVenture() {
            this.isShowEco = false;
            this.isShowCohort = false;
            setTimeout(() => {
                this.isShowIdea = true;
            }, 500);
            if (this.state.currentUser?.metadata?.showVentureAlert) {
                this.isShowVenture = true;
            }
        },
        async saveForm(data, index) {
            if (this.isSaving) return false;
            console.log(data, index, "index");
            if (Object.keys(data)?.find((k) => k === "modelField")) {
                delete data.modelField;
            }
            this.isSaving = true;
            try {
                let response = null;
                if (index === 0) {
                    this.formData = { ...data };
                } else if (index === 1) {
                    this.formData.targetCustomer = {
                        name: data?.help,
                        targetCustomerUserType: data.customerType,
                    };
                } else if (index === 2) {
                    this.formData = { ...this.formData, ...data };
                } else if (index === 3) {
                    this.formData = { ...this.formData, ...data };
                } else if (index === 4) {
                    this.formData = { ...this.formData, ...data };
                } else if (index === 5) {
                    this.formData = { ...this.formData, ...data };
                } else if (index === 6) {
                    console.log(this.formData, "formData");
                    const ventureParam = {
                        name: this.formData?.name,
                        description: this.formData?.description,
                        users: {
                            connect: [{ id: this.state.currentUser?.id }],
                        },
                        hypotheses: {
                            create: [
                                {
                                    name:
                                        "create " + this.formData?.description,
                                },
                            ],
                        },
                        targetCustomerUsers: {},
                        createdBy: {
                            connect: { id: this.state.currentUser?.id },
                        },
                        // contacts: {
                        //   create: [{
                        //     user: { connect: { id: this.state.currentUser?.id } },
                        //     status: 'NEW_CONTACT'
                        //   }]
                        // }
                    };
                    const tagConnect = [],
                        tagCreate = [],
                        tagSearch = [];
                    // age
                    this.formData?.age?.map((age) => {
                        if (age?.id && isNaN(age?.id)) {
                            tagConnect.push({ id: age?.id });
                            tagSearch.push({
                                nameLower: age?.name?.toLowerCase(),
                                status: age?.status,
                                targetType: age?.targetType,
                                type: "DEMOGRAPHIC",
                                category: age?.category,
                            });
                        } else {
                            // create a new tag
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "AGE",
                                name: age?.name,
                                nameLower: age?.name.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                            });
                            tagSearch.push({
                                nameLower: age?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "AGE",
                            });
                        }
                    });
                    // gender
                    this.formData?.gender?.map((gender) => {
                        if (gender?.id && isNaN(gender?.id)) {
                            tagConnect.push({ id: gender?.id });
                            tagSearch.push({
                                nameLower: gender?.name?.toLowerCase(),
                                status: gender?.status,
                                targetType: gender?.targetType,
                                type: "DEMOGRAPHIC",
                                category: gender?.category,
                            });
                        } else {
                            // create a new tag
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "GENDER",
                                name: gender?.name,
                                nameLower: gender?.name.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                            });
                            tagSearch.push({
                                nameLower: gender?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "GENDER",
                            });
                        }
                    });
                    // race
                    this.formData?.ethnicity?.map((ethnicity) => {
                        if (ethnicity?.id && isNaN(ethnicity?.id)) {
                            tagConnect.push({ id: ethnicity?.id });
                            tagSearch.push({
                                nameLower: ethnicity?.name?.toLowerCase(),
                                status: ethnicity?.status,
                                targetType: ethnicity?.targetType,
                                type: "DEMOGRAPHIC",
                                category: ethnicity?.category,
                            });
                        } else {
                            // create a new tag
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "ETHNICITY",
                                name: ethnicity?.name,
                                nameLower: ethnicity?.name.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                            });
                            tagSearch.push({
                                nameLower: ethnicity?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "ETHNICITY",
                            });
                        }
                    });
                    // income
                    this.formData?.income?.map((income) => {
                        if (income?.id && isNaN(income?.id)) {
                            tagConnect.push({ id: income?.id });
                            tagSearch.push({
                                nameLower: income?.name?.toLowerCase(),
                                status: income?.status,
                                targetType: income?.targetType,
                                type: "DEMOGRAPHIC",
                                category: income?.category,
                            });
                        } else {
                            // create a new tag
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "INCOME",
                                name: income?.name,
                                nameLower: income?.name.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                            });
                            tagSearch.push({
                                nameLower: income?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "INCOME",
                            });
                        }
                    });
                    // revenue
                    this.formData?.revenue?.map((revenue) => {
                        if (revenue?.id && isNaN(revenue?.id)) {
                            tagConnect.push({ id: revenue?.id });
                            tagSearch.push({
                                nameLower: revenue?.name?.toLowerCase(),
                                status: revenue?.status,
                                targetType: revenue?.targetType,
                                type: "DEMOGRAPHIC",
                                category: revenue?.category,
                            });
                        } else {
                            // create a new tag
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "REVENUE",
                                name: revenue?.name,
                                nameLower: revenue?.name.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                            });
                            tagSearch.push({
                                nameLower: revenue?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "REVENUE",
                            });
                        }
                    });
                    // employee_count
                    this.formData?.employee_count?.map((employee_count) => {
                        if (employee_count?.id && isNaN(employee_count?.id)) {
                            tagConnect.push({ id: employee_count?.id });
                            tagSearch.push({
                                nameLower: employee_count?.name?.toLowerCase(),
                                status: employee_count?.status,
                                targetType: employee_count?.targetType,
                                type: "DEMOGRAPHIC",
                                category: employee_count?.category,
                            });
                        } else {
                            // create a new tag
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "EMPLOYEE_COUNT",
                                name: employee_count?.name,
                                nameLower: employee_count?.name.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                            });
                            tagSearch.push({
                                nameLower: employee_count?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "EMPLOYEE_COUNT",
                            });
                        }
                    });
                    // location
                    for (let i = 0; i < this.formData?.location?.length; i++) {
                        const lct = this.formData?.location[i];
                        if (lct.id) {
                            tagConnect.push({ id: lct?.id });
                        } else {
                            const res =
                                await this.actions.google.searchGooglePlaceById(
                                    { placeId: lct?.place_id }
                                );
                            console.log(res, "location detail");
                            const addressComponent = res.address_components[0];
                            var city;
                            var state;
                            var country;
                            res?.address_components?.map((addressComponent) => {
                                if (
                                    _.indexOf(
                                        addressComponent.types,
                                        "neighborhood"
                                    ) > -1
                                )
                                    city = addressComponent["short_name"];
                                if (
                                    _.indexOf(
                                        addressComponent.types,
                                        "locality"
                                    ) > -1
                                )
                                    city = addressComponent["short_name"];
                                if (
                                    _.indexOf(
                                        addressComponent.types,
                                        "administrative_area_level_1"
                                    ) > -1
                                )
                                    state = addressComponent["short_name"];
                                if (
                                    _.indexOf(
                                        addressComponent.types,
                                        "country"
                                    ) > -1
                                )
                                    country = addressComponent["short_name"];
                            });

                            if (city) {
                                const item = this.state.tag.tags.find(
                                    (t) => t?.name === city
                                );
                                const update = {
                                    type: "LOCATION",
                                    category: "CITY",
                                    name: city,
                                    nameLower: city.toLowerCase(),
                                    status: "APPROVED",
                                    targetType: "ALL",
                                };
                                if (item?.id) {
                                    tagConnect.push({ id: item?.id });
                                } else {
                                    tagCreate.push(update);
                                }
                                tagSearch.push({
                                    nameLower: city?.toLowerCase(),
                                    status: "APPROVED",
                                    targetType: "ALL",
                                    type: "LOCATION",
                                    category: "CITY",
                                });
                            }
                            if (state) {
                                const item = this.state.tag.tags.find(
                                    (t) => t?.name === state
                                );
                                const update = {
                                    type: "LOCATION",
                                    category: "STATE",
                                    name: state,
                                    nameLower: state.toLowerCase(),
                                    status: "APPROVED",
                                    targetType: "ALL",
                                };
                                if (item?.id) {
                                    tagConnect.push({ id: item?.id });
                                } else {
                                    tagCreate.push(update);
                                }
                                tagSearch.push({
                                    nameLower: state?.toLowerCase(),
                                    status: "APPROVED",
                                    targetType: "ALL",
                                    type: "LOCATION",
                                    category: "STATE",
                                });
                            }
                            if (country) {
                                const item = this.state.tag.tags.find(
                                    (t) => t?.name === country
                                );
                                const update = {
                                    type: "LOCATION",
                                    category: "COUNTRY",
                                    name: country,
                                    nameLower: country.toLowerCase(),
                                    status: "APPROVED",
                                    targetType: "ALL",
                                };
                                if (item?.id) {
                                    tagConnect.push({ id: item?.id });
                                } else {
                                    tagCreate.push(update);
                                }
                                tagSearch.push({
                                    nameLower: country?.toLowerCase(),
                                    status: "APPROVED",
                                    targetType: "ALL",
                                    type: "LOCATION",
                                    category: "COUNTRY",
                                });
                            }
                        }
                    }
                    // interest and activity
                    this.formData?.interest?.map((interest) => {
                        if (interest?.id && isNaN(interest?.id)) {
                            tagConnect.push({ id: interest?.id });
                            tagSearch.push({
                                nameLower: interest?.name?.toLowerCase(),
                                status: interest?.status,
                                targetType: interest?.targetType,
                                type: "DEMOGRAPHIC",
                                category: interest?.category,
                            });
                        } else {
                            tagCreate.push({
                                type: "DEMOGRAPHIC",
                                category: "DESCRIPTORS",
                                name: interest?.name,
                                targetType: "INDIVIDUALS",
                                status: "NEW",
                            });
                            tagSearch.push({
                                nameLower: interest?.name?.toLowerCase(),
                                status: "NEW",
                                targetType: "INDIVIDUALS",
                                type: "DEMOGRAPHIC",
                                category: "DESCRIPTORS",
                            });
                        }
                    });

                    console.log(tagSearch, tagCreate, tagConnect);
                    // First check if this TargetCustomer already exists
                    const { doesTargetCustomerExist } =
                        await this.actions.targetCustomer.doesTargetCustomerExist(
                            {
                                type: this.formData?.targetCustomer
                                    ?.targetCustomerUserType?.name,
                                tags: tagSearch,
                            }
                        );
                    console.log(
                        doesTargetCustomerExist,
                        "doesTargetCustomerExist"
                    );
                    ventureParam.targetCustomerUsers = {
                        create: [
                            {
                                name: this.formData.targetCustomer?.name,
                                researchPrompt:
                                    this.formData.reasonSecond?.label,
                                emailTemplates: {
                                    create: c.emailTemplates,
                                },
                                interviewScripts: {
                                    create: [
                                        {
                                            ...c.interviewScript,
                                            createdBy: {
                                                connect: {
                                                    id: this.state.currentUser
                                                        ?.id,
                                                },
                                            },
                                        },
                                    ],
                                },
                                targetCustomer: doesTargetCustomerExist?.id
                                    ? {
                                          connect: {
                                              id: doesTargetCustomerExist?.id,
                                          },
                                      }
                                    : {
                                          create: {
                                              name: lowerFirst(
                                                  doesTargetCustomerExist?.name
                                              ),
                                              tags: {
                                                  create: tagCreate,
                                                  connect: tagConnect,
                                              },
                                              type: this.formData
                                                  ?.targetCustomer
                                                  ?.targetCustomerUserType
                                                  ?.name,
                                          },
                                      },
                                createdBy: {
                                    connect: { id: this.state.currentUser?.id },
                                },
                            },
                        ],
                    };
                    const opportunityData = {
                        type: "ACTIVITY",
                        name: "",
                        desireText: this.formData.reasonFirst?.value,
                        problemContext: this.formData?.reasonFour,
                        problems: { connect: [], create: [] },
                    };
                    if (this.formData?.reasonSecond?.id) {
                        opportunityData.actionStep = {
                            connect: { id: this.formData?.reasonSecond?.id },
                        };
                    } else if (this.formData?.reasonSecond?.label) {
                        opportunityData.actionStep = {
                            create: {
                                name: this.formData?.reasonSecond?.label,
                                type: "ACTION",
                                status: "NEW",
                            },
                        };
                    }
                    ventureParam.opportunities = { create: [opportunityData] };
                    this.formData?.reasonThird?.map((problem) => {
                        if (!problem?.id && problem?.name) {
                            opportunityData.problems.create.push({
                                type: "PROBLEM",
                                economy: {
                                    create: {
                                        name: problem?.name,
                                        problem: problem?.name,
                                    },
                                },
                            });
                        } else if (problem?.id) {
                            opportunityData.problems.create.push({
                                type: "PROBLEM",
                                economy: { connect: { id: problem?.id } },
                            });
                        }
                    });
                    console.log(ventureParam, "param");
                    response = await this.actions.saveFormData({
                        mutation: "saveVenture",
                        params: ventureParam,
                    });

                    const ventures = await this.actions.venture.getVentures({
                        where: {id: response?.id},
                        getValues: true,
                        query: 'ventureDetail'
                    })

                    console.log(ventures, 'ventures')

                    response = ventures?.[0]
                    // add Member and Advisor groups
                    const groups = [
                        {
                            name: "Member",
                            description: "Member",
                            isArchive: false,
                            venture: { connect: { id: response?.id } },
                            type: "ROLE",
                            permissions: {
                                create: [
                                    {
                                        type: "data",
                                        name: "Manage Venture",
                                        description: "Venture management",
                                        collection: {
                                            create: {
                                                name: "Venture",
                                            },
                                        },
                                        access: {
                                            create: {
                                                delete: {
                                                    create: {
                                                        access: false,
                                                        mustOwn: true,
                                                    },
                                                },
                                                read: {
                                                    create: {
                                                        access: true,
                                                        mustOwn: false,
                                                    },
                                                },
                                                create: {
                                                    create: {
                                                        access: true,
                                                        mustOwn: true,
                                                    },
                                                },
                                                update: {
                                                    create: {
                                                        access: true,
                                                        mustOwn: false,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            name: "Advisor",
                            description: "Advisor",
                            isArchive: false,
                            venture: { connect: { id: response?.id } },
                            type: "ROLE",
                            permissions: {
                                create: [
                                    {
                                        type: "data",
                                        name: "Manage Ventures",
                                        description: "Venture management",
                                        collection: {
                                            create: {
                                                name: "Venture",
                                            },
                                        },
                                        access: {
                                            create: {
                                                delete: {
                                                    create: {
                                                        access: false,
                                                        mustOwn: true,
                                                    },
                                                },
                                                read: {
                                                    create: {
                                                        access: true,
                                                        mustOwn: false,
                                                    },
                                                },
                                                create: {
                                                    create: {
                                                        access: false,
                                                        mustOwn: true,
                                                    },
                                                },
                                                update: {
                                                    create: {
                                                        access: false,
                                                        mustOwn: false,
                                                    },
                                                },
                                            },
                                        },
                                    },
                                ],
                            },
                        },
                    ];

                    groups.map(
                        async (g) =>
                            await this.actions.group.saveGroup({
                                data: g,
                            })
                    );

                    const opportunityConnect = [];
                    const solutions = [];
                    for (let i = 0; i < this.formData?.solutions?.length; i++) {
                        const o = this.formData?.solutions[i];
                        const { saveOpportunity } =
                            await this.actions.opportunity.saveOpportunity({
                                data: {
                                    name: o?.label,
                                    type: "SOLUTION",
                                    venture: { connect: { id: response?.id } },
                                    actionStep: {
                                        create: {
                                            name: o?.label,
                                            type: "SOLUTION",
                                        },
                                    },
                                },
                            });
                        const saveOpportunityLink =
                            await this.actions.saveFormData({
                                mutation: "saveOpportunityLink",
                                params: {
                                    parent: {
                                        connect: {
                                            id: response?.opportunities?.[0]
                                                ?.id,
                                        },
                                    },
                                    child: {
                                        connect: {
                                            id: saveOpportunity?.id,
                                        },
                                    },
                                },
                            });
                        solutions.push({ id: saveOpportunityLink?.id });
                        opportunityConnect.push({
                            id: saveOpportunityLink?.child?.id,
                        });
                        await this.actions.opportunity.saveOpportunity({
                            where: { id: saveOpportunity?.id },
                            data: {
                                parentOpportunityLinks: {
                                    connect: [{ id: saveOpportunityLink?.id }],
                                },
                            },
                        });
                    }
                    await this.actions.opportunity.saveOpportunity({
                        where: { id: response?.opportunities?.[0]?.id },
                        data: {
                            childOpportunityLinks: {
                                connect: solutions,
                            },
                        },
                    });
                    await this.actions.saveFormData({
                        mutation: "saveVenture",
                        params: {
                            targetCustomerUsers: {
                                update: [
                                    {
                                        where: {
                                            id: response?.targetCustomerUsers[0]
                                                ?.id,
                                        },
                                        data: {
                                            opportunities: {
                                                connect: [
                                                    {
                                                        id: response
                                                            ?.opportunities?.[0]
                                                            ?.id,
                                                    },
                                                    ...opportunityConnect,
                                                ],
                                            },
                                        },
                                    },
                                ],
                            },
                            opportunities: {
                                connect: [
                                    {
                                        id: response?.opportunities?.[0]?.id,
                                    },
                                    ...opportunityConnect,
                                ],
                            },
                        },
                        where: { id: response?.id },
                    });
                    if (
                        this.state.currentUser?.userGroups?.find(
                            (u) => u?.group?.name === "ecosystem-user"
                        )
                    ) {
                        await this.actions.company.saveCompany({
                            where: {
                                id: this.state.currentUser?.companies?.[0]?.id,
                            },
                            data: {
                                ventures: { connect: [{ id: response?.id }] },
                            },
                        });
                        await this.actions.user.saveUser({
                            where: { id: this.state.currentUser?.id },
                            data: {
                                metadata: {
                                    ...this.state.currentUser?.metadata,
                                    showEcosystemModal: false,
                                    firstLogin: false,
                                },
                            },
                        });
                    }
                    console.log(
                        this.state.currentUser?.metadata?.showCohortModal,
                        "showCohortModal"
                    );
                    if (this.state.currentUser?.metadata?.showCohortModal) {
                        await this.actions.venture.addVentureTo({
                            userId: this.state.currentUser?.id,
                            ventureId: response?.id,
                            cohortId: this.state.currentUser?.cohorts?.[0]?.id,
                        });
                        // await this.actions.user.saveUser({
                        //   where: {id: this.state.currentUser?.id},
                        //   data: {
                        //     metadata: {
                        //       ...this.state.currentUser?.metadata,
                        //       showCohortModal: false,
                        //       firstLogin: false,
                        //     }
                        //   }
                        // })
                    }

                    this.isShowIdea = false;
                    this.$router.push({
                        name: "Venture Dashboard",
                        params: { ventureId: response.id },
                    });
                    // await this.getVenturesData();
                }
            } catch (e) {
                console.log(e);
            } finally {
                this.isSaving = false;
            }
        },
        getCompanies() {
            this.companies = this.state.currentUser?.companies?.map(
                (company) => {
                    let cohorts = 0;
                    company?.programs?.map(
                        (p) => (cohorts += p?.cohorts?.length)
                    );
                    return {
                        id: company?.id,
                        title: company?.name,
                        description: company?.description,
                        subtitles: [
                            {
                                icon: "LightBulbIcon",
                                name: "Ventures",
                                count: company?.ventures?.length,
                            },
                            {
                                icon: "NewspaperIcon",
                                name: "Programs",
                                count: company?.programs?.length,
                            },
                            {
                                icon: "UsersIcon",
                                name: "Cohorts",
                                count: cohorts,
                            },
                        ],
                    };
                }
            );
        },
        async getResourcesData() {
            if (this.isLoading) return false;
            this.isLoading = true;
            await this.actions.resource.getResources({
                orderBy: "createdAt_DESC",
                first: 2,
            });
            this.resources = _.map(
                this.state.resource.resources,
                (resource) => {
                    return {
                        id: resource.id,
                        title: resource?.name || "",
                        desc: resource?.description || "",
                        items: [{ label: resource?.type }],
                    };
                }
            );
            this.isLoading = false;
        },
        async getVenturesData() {
            if (this.isLoading) return false;
            this.isLoading = true;
            await this.actions.venture.getVentures({
                orderBy: "createdAt_DESC",
                first: 2,
                where: {
                    users_some: {
                        id: this.state.currentUser?.id,
                    },
                },
                query: "venturesList",
            });
            await this.actions.venture.getTotalVentures();
            console.log(this.state.venture.ventures, "ventures");
            await this.actions.milestone.getMilestones();
            Object.values(this.state.venture.ventures)?.map((venture) => {
                const currentMilestone = json(venture?.milestones)?.sort(
                    (a, b) => (a?.sortOrder > b?.sortOrder ? -1 : 1)
                )?.[0];
                this.ventures.push({
                    id: venture.id,
                    title: venture?.name || "",
                    description: venture?.description || "",
                    actionLabel:
                        currentMilestone?.tagLabel || "Getting Started",
                    subtitles: [
                        {
                            icon: "MapIcon",
                            name: "Target Customers",
                            count: venture?.targetCustomerUsers?.filter(
                                (t) => !t?.isArchived
                            )?.length,
                        },
                        {
                            icon: "ExclamationIcon",
                            name: "Opportunities",
                            count: venture?.opportunities?.filter(
                                (o) => !o?.isArchived
                            ).length,
                        },
                        {
                            icon: "UsersIcon",
                            name: "Team",
                            count: venture?.users?.length,
                        },
                    ],
                });
            });
            this.isLoading = false;
        },
        async onCloseWelcome(isYes) {
            try {
                this.isShow = false;
                if (isYes) {
                    this.isShowIdea = true;
                }
                if (this.state.currentUser?.metadata?.showVentureAlert) {
                    this.isShowVenture = true;
                }
                await this.actions.user.saveUser({
                    where: { id: this.state.currentUser?.id },
                    data: {
                        metadata: {
                            ...this.state.currentUser?.metadata,
                            firstLogin: false,
                        },
                    },
                });
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>
